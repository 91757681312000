@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Regular.ttf");
  font-style: normal;
  font-family: LeagueSpartan;
}

@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Thin.ttf");
  font-style: normal;
  font-weight: 100;
  font-family: LeagueSpartan100;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-ExtraLight.ttf");
  font-style: normal;
  font-weight: 200;
  font-family: LeagueSpartan200;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-family: LeagueSpartan300;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-family: LeagueSpartan400;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-family: LeagueSpartan500;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-family: LeagueSpartan600;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-family: LeagueSpartan700;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
  font-family: LeagueSpartan800;
}
@font-face {
  src: url("./fonts/League_Spartan/static/LeagueSpartan-Black.ttf");
  font-style: normal;
  font-weight: 900;
  font-family: LeagueSpartan900;
}

/* Rubik */

@font-face {
  src: url("./fonts/Rubik/static/Rubik-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-family: Rubik300;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-family: Rubik400;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-family: Rubik500;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-family: Rubik600;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-family: Rubik700;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
  font-family: Rubik800;
}
@font-face {
  src: url("./fonts/Rubik/static/Rubik-Black.ttf");
  font-style: normal;
  font-weight: 900;
  font-family: Rubik900;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
